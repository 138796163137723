import { IMAGE_BACKGROUD_CLIMA, IMAGE_CLIMA_NUBLADO, IMAGE_CLIMA_NUVEM, IMAGE_CLIMA_SOL, IMAGE_CLIMA_SOLCHUVA, IMAGE_CLIMA_TEMPESTADE } from "../../../images";
import { BackgroudTotal, CardProximaSemana, ClimaContainer, ClimaHoje, ClimaProximaSemana, DiasSemanas, ImagemClima, Linha, Texto, Titulo } from "./styles";
import { useQuery } from "react-query";
import { IClima } from "../../../utils/types";
import { AiOutlineCloud } from 'react-icons/ai';
import { FiWind } from 'react-icons/fi';
import { MdOutlineWaterDrop } from 'react-icons/md';
import { FiArrowDown, FiArrowUp } from  'react-icons/fi';

const DestaqueClima = () => {

    const _tipoClimasImg = (tipo: string | undefined) => {
        switch (tipo) {
            case 'storm':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'snow':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'hail':
                return IMAGE_CLIMA_TEMPESTADE;
            case 'fog':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'clear_day':
                return IMAGE_CLIMA_SOL;
            case 'clear_night':
                return IMAGE_CLIMA_NUVEM;
            case 'cloud':
                return IMAGE_CLIMA_NUVEM;
            case 'cloudly_day':
                return IMAGE_CLIMA_NUVEM;
            case 'cloudly_night':
                return IMAGE_CLIMA_NUVEM;
            case 'none_day':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'none_day':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'none_night':
                return IMAGE_CLIMA_SOLCHUVA;
            case 'rain':
                return IMAGE_CLIMA_SOLCHUVA;
            default:
                return '-';
        }
    }

    let { data } = useQuery<IClima, any>('DadosClima', async () => {
        const res = await fetch("https://api.hgbrasil.com/weather?format=json-cors&key=56b4d0cf&lat=-19.9007359&lon=-43.9904972&user_ip=remote");
        return res.json();
    });


    return (
        <BackgroudTotal background={IMAGE_BACKGROUD_CLIMA}>
            <ClimaContainer>
                {(data && data.results.forecast) &&
                    <ClimaHoje>
                        <div className="row align-items-center">
                            <div className="col-5">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <Titulo>Hoje</Titulo>
                                    </div>
                                    <div className="col-12 d-flex justify-content-around  align-items-center">
                                        <ImagemClima style={{ width: '70px', height: '80px' }} src={_tipoClimasImg(data.results.condition_slug)} />
                                        <Texto style={{ fontSize: '8rem', color: 'white', fontWeight: '700', margin: '0' }}>{data.results.temp}º</Texto>
                                    </div>

                                    <div className="col-12 d-flex flex-column align-items-centern align-items-center justify-content-center">
                                        <h5 style={{ color: '#F47920', textTransform: 'uppercase', margin: '0', fontSize: '1.3rem' }}>{data.results.city_name}</h5>
                                        <p style={{ color: '#F47920', fontSize: '1rem', textTransform: 'uppercase' }}> {data.results.forecast[0].weekday} -{data.results.date}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-1 d-flex justify-content-center">
                                <Linha />
                            </div>

                            <div className="col-6">
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <div className="d-flex justify-content-around align-items-center">
                                            <AiOutlineCloud size={'50px'} color={'white'} />
                                            <div style={{ width: '60%' }}>
                                                <h5 style={{ color: 'white' }}>Nuvens</h5>
                                                <h3 style={{ color: '#F47920' }}>{data.results.cloudiness}%</h3>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <FiWind size={'50px'} color={'white'} />
                                            <div style={{ width: '60%' }}>
                                                <h5 style={{ color: 'white' }}>Vento</h5>
                                                <h3 style={{ color: '#F47920' }}>{data.results.wind_speedy}</h3>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <MdOutlineWaterDrop size={'50px'} color={'white'} />
                                            <div style={{ width: '60%' }}>
                                                <h5 style={{ color: 'white' }}>Umidade</h5>
                                                <h3 style={{ color: '#F47920' }}>{data.results.humidity}%</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-around align-items-center">
                                            <FiArrowUp size={'50px'} color={'white'} />
                                            <div style={{ width: '60%' }}>
                                                <h5 style={{ color: 'white' }}>Temp. Max</h5>
                                                <h3 style={{ color: '#F47920' }}>{data.results.forecast[0].max}º</h3>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <FiArrowDown size={'50px'} color={'white'} />
                                            <div style={{ width: '60%' }}>
                                                <h5 style={{ color: 'white' }}>Temp. Min</h5>
                                                <h3 style={{ color: '#F47920' }}>{data.results.forecast[0].min}º</h3>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </ClimaHoje>
                }

                {(data && data.results.forecast) &&
                    <ClimaProximaSemana>
                        <div className="row justify-content-around" style={{}}>
                            {data.results.forecast.filter((item, index) => index !== 0 && index !== 9 && index !== 8).map((item, index) => (
                                <div className="col d-flex justify-content-center">
                                    <CardProximaSemana>
                                        <DiasSemanas>
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5 style={{ color: 'white', textTransform: 'uppercase' }}>{item.weekday}</h5>
                                                </div>
                                                <div className="col-6">
                                                    <ImagemClima style={{ width: '25px', height: '25px' }} src={_tipoClimasImg(item.condition)} />
                                                </div>
                                            </div>
                                        </DiasSemanas>
                                        <div className=" d-flex flex-column justify-content-center aling-items-center text-center">
                                            <h5 style={{ color: 'white', margin: '0', fontSize: '.8rem' }}>Temp. Max</h5>
                                            <h3 style={{ color: '#F47920' }}>{item.max}º</h3>

                                        </div>

                                        <div className=" d-flex flex-column justify-content-center aling-items-center text-center">
                                            <h5 style={{ color: 'white', margin: '0', fontSize: '.8rem' }}>Temp. Min</h5>
                                            <h3 style={{ color: '#F47920' }}>{item.min}º</h3>
                                        </div>
                                    </CardProximaSemana>
                                </div>
                            ))}
                        </div>
                    </ClimaProximaSemana>
                }
            </ClimaContainer>
        </BackgroudTotal >
    )

}

export default DestaqueClima;
