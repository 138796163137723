import React from 'react';
import ReactDOM from 'react-dom/client';
import Views from './views';

import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<Views />);