import { useState } from "react";
import { prod, token, url_homo, url_prod } from "../../utils/constantes";

const useApi = (basePath = (prod ? url_prod : url_homo)) => {
    const [load, setLoad] = useState(false);

    const get = async (url = '') => {
        setLoad(true);

        let options = {
            headers: {
                'Content-Type': 'application/json',
                'LocalAcesso': 'Site',
                'Autorizacao': token,
                'HeaderUsuario': window.navigator.userAgent,
                'IpAcesso': '::1'
            },
            method: 'GET'
        }

        !prod && console.log('Headers', options)
        !prod && console.log('Path', basePath + url)

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        setLoad(false);
        return { data, response };
    }

    const post = async (url = '', formData = {}, file = false) => {
        setLoad(true);

        let options = {
            headers: {
                'Content-Type': 'application/json',
                'LocalAcesso': 'Site',
                'Autorizacao': token
            },
            method: 'POST',
            body: JSON.stringify(formData) || undefined
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();


        setLoad(false);
        return { data, response };
    }

    const put = async (url = '', formData = {}) => {
        setLoad(true);

        let options = {
            headers: {
                'Content-Type': 'application/json',
                'LocalAcesso': 'Site',
                'Autorizacao': token
            },
            method: 'PUT',
            body: JSON.stringify(formData) || undefined
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        setLoad(false);
        return { data, response };
    }

    const remove = async (url = '', formData = {}) => {
        setLoad(true);

        let options = {
            headers: {
                'Content-Type': 'application/json',
                'LocalAcesso': 'Site',
                'Autorizacao': token
            },
            method: 'DELETE'
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        setLoad(false);
        return { data, response };
    }

    return { load, get, post, put, remove };
}

export default useApi;