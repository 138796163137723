import { FC } from "react";
import { IMAGE_DEPOIMENTO } from "../../../images";
import { BackGroundDepoimentos, SubTitleDepoimento, TextDepoimento, TitleDepoimento } from "./styles";
import { useQuery } from "react-query";
import { Carousel } from "react-bootstrap";
import { useParams } from "react-router-dom";

import useApi from "../../../hooks/useApi";

type IParams = {
    departamentos: string
}

interface PropsDepoimentoI {
    nome: string
    descricao: string
    usuario: string
}

const Depoimento = ({ nome, usuario, descricao }: PropsDepoimentoI) => {
    let user = usuario.split(' ');
    let userLast = user.length;
    userLast = userLast - 1;
    return (
        <div style={{ width: '750px', height: 'auto', position: "relative", top: '-8%', left: '15%' }}>
            <TitleDepoimento>{`Parabéns, ${user[0]} ${user[userLast] ? user[userLast] : ''}!`}</TitleDepoimento>
            <TextDepoimento>"{descricao.toUpperCase()}"</TextDepoimento>
            <SubTitleDepoimento>{nome} - Associado(a) Premium</SubTitleDepoimento>
        </div>
    )
}

const Depoimentos = () => {
    const api = useApi();
    const { departamentos } = useParams<IParams>();
    let tipo_protocolo = 1;

    if (departamentos == 'eventos') {
        tipo_protocolo = 2;
    }

    const { data } = useQuery('depoimentos', async () => {
        const res = await api.get('ViewDepoimentosParticipantes/consultar/?usuario!=NULL&tipo_protocolo=' + tipo_protocolo + '&order=RAND() LIMIT 21');
        if (res.data.erro) {
            return [];
        }
        return Object.values(res.data);
    });

    return (

        <BackGroundDepoimentos style={{ backgroundSize: '100%' }} background={IMAGE_DEPOIMENTO}>
            <Carousel
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                fade
                indicators={false}
                controls={false}
            >
                {data && data.length > 0 && data.map((value: any, key: number) => (
                    <Carousel.Item key={key} interval={(3000)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Depoimento nome={value.nome} usuario={value.usuario} descricao={value.comentario} />
                    </Carousel.Item>
                ))}
            </Carousel>
        </BackGroundDepoimentos>
    )
}

export default Depoimentos;
