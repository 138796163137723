export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const firstAndLastName = (string: string) => {
    let stringList = string.trim().split(' ');
    return `${stringList[0]} ${stringList[(stringList.length - 1)]}`;
}

export const duracao = (duracao: string) => {
    let miliseconds = 0;
    if (duracao && duracao != '') {
        let split = duracao.toString().split(':');
        miliseconds = 1000 * (parseInt(split[2]) + (parseInt(split[1]) * 60 + (parseInt(split[0]) * 60)));
    }
    return miliseconds;
}