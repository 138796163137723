import { prod, url_homo, url_prod } from '../../utils/constantes';
import { IBanners } from '../../utils/types';
import { Carousel } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Imagens } from './styles';
import { useParams } from 'react-router-dom';
import { duracao } from '../../utils/functions';

import Iframe from 'react-iframe';
import useApi from '../../hooks/useApi';
import DestaqueClima from './components/DestaqueClima';
import Depoimentos from "./components/Depoimentos";

type IParams = {
    departamentos: string
}

const Home = () => {
    const api = useApi();
    const { departamentos } = useParams<IParams>();

    const { data } = useQuery(['banners', departamentos], async ({ queryKey }) => {
        const res = await api.get(`TvCorporativaBanners/ObterBanners/${queryKey[1]}`);
        if (res.data.erro) {
            return [];
        }
        return Object.values(res.data);
    });

    return (
        <Carousel
            controls={false}
            indicators={false}
            pause={false}
            fade
        >
            {data && data.length > 0 && data.map((item: IBanners | any, i: number) => {
                if (item.Tipo === 'I') {
                    return (
                        <Carousel.Item style={{ position: 'relative' }} key={i} interval={duracao(item.Duracao)}>
                            <Imagens
                                background={`${(prod ? url_prod : url_homo)}bancoImagens/${item.Imagem}`}
                            />
                        </Carousel.Item>
                    )
                }

                if (item.Tipo === 'V') {
                    return (
                        <Carousel.Item style={{ position: 'relative' }} key={i} interval={duracao(item.Duracao)}>
                            <video
                                width="100%"
                                height="100%"
                                src={`${(prod ? url_prod : url_homo)}bancoImagens/${item.Imagem}`}
                                autoPlay={true}
                                muted
                                loop
                            />
                        </Carousel.Item>
                    )
                }

                if (item.Tipo === 'U') {
                    return (
                        <Carousel.Item style={{ position: 'relative' }} key={i} interval={duracao(item.Duracao)}>
                            <Iframe
                                width="100%"
                                height="100%"
                                url={item.Url}
                                frameBorder={0}
                                allowFullScreen
                            />
                        </Carousel.Item>
                    )
                }

                if (item.Tipo === 'C') {
                    return (
                        <Carousel.Item style={{ position: 'relative' }} key={i} interval={duracao(item.Duracao)}>
                            <DestaqueClima />
                        </Carousel.Item>
                    )
                }

                if (item.Tipo === 'D') {
                    return (
                        <Carousel.Item style={{ position: 'relative' }} key={i} interval={duracao(item.Duracao)}>
                            <Depoimentos />
                        </Carousel.Item>
                    )
                }
            })}
        </Carousel>
    )
}

export default Home;