export const IMAGE_BANNER_DESATQUE = require('./destaques.jpg');
export const IMAGE_BANNER_MONITORIA = require('./monitoria.jpg');
export const IMAGE_BANNER_ANIVERSARIO = require('./aniversario.jpg');
export const IMAGE_BANNER_ANIVERSARIANTES = require('./aniversariantes.jpg');

export const IMAGE_BANNER_DESATQUE1 = require('./destaques-mes-p1.jpg');
export const IMAGE_BANNER_DESATQUE2 = require('./destaques-mes-p2.jpg');
export const IMAGE_BACKGROUD_CLIMA = require('./Belo-Horizonte.jpg')
export const IMAGE_DEPOIMENTO = require('./imagem-depoimento.jpg')


export const IMAGE_CLIMA_SOL = require('./sol.png')
export const IMAGE_CLIMA_NUVEM = require('./nuvem.png')
export const IMAGE_CLIMA_NUBLADO = require('./nublado.png')
export const IMAGE_CLIMA_NUVEMSOL = require('./nuvemsol.png')
export const IMAGE_CLIMA_PARCIALMENTE = require('./parcialmente.png')
export const IMAGE_CLIMA_RAIO = require('./raio.png')
export const IMAGE_CLIMA_SOLCHUVA = require('./solchuva.png')
export const IMAGE_CLIMA_TEMPESTADE = require('./tempestade.png')
