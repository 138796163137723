import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Home";
import GlobalStyle from "../themes/global";

import { QueryClientProvider, QueryClient } from 'react-query';
import Eventos from "./Eventos";
const queryClient = new QueryClient();

const Views = () => {

    setInterval(() => {
        window.location.reload()
    }, (1000 * 60 * 10));

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <GlobalStyle />
                <Routes>
                    <Route path="/:departamentos" element={<Home />} />                    
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

export default Views;