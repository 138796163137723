import styled from 'styled-components';
import { COLORS } from '../../../themes/colors';

interface IDestaque {
    background: string;
}

interface IColaboradoresImage {
    background: string;
}

interface ITitle {
    color?: string;
}

interface Clima {
    background: string;
}

interface Imagem {
    src: Imagem;
}

interface IProgress {
    time?: number;
}

interface DepoimentoBackGround {
    background: string;
}


export const Destaque = styled.div<IDestaque>`
    background-image: url('${IDestaque => IDestaque.background}');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;

    padding-top: 15%;
`;

export const DestaqueHeader = styled.div`
    text-align:center ;    
    margin-bottom: 2rem;
`;

export const Title = styled.h1<ITitle>`
    color: ${ITitle => ITitle.color ?? COLORS.WHITE};

    margin: 0;
    padding: 0;

    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
`;

export const ColaboradoresContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    gap: .3rem;

    width: 100%;
`;

export const Colaboradores = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;

    background-color: ${COLORS.WHITE};

    padding: .2rem;
    border-radius: .5rem;
`;

export const ColaboradoresImage = styled.img`            
    margin-bottom: .2rem;
    object-fit: cover;
    object-position: center top;

    width: 100%;
    height: 100px;
`;

export const ColaboradoresInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ColaboradoresInfoNome = styled.div`
    font-size: .6rem;
    font-weight: bold;
    text-align: center;

    color: ${COLORS.PRIMARY};
`;

export const ColaboradoresInfoDataNascimento = styled.div`
    font-size: .6rem;
    font-weight: bold;
    text-align: center;

    color: ${COLORS.PRIMARY};
`;

export const ColaboradoresInfoDepartamento = styled.div`
    font-size: .6rem;
    font-weight: bold;
    text-align: center;

    margin-top: .4rem;

    color: ${COLORS.SECONDARY};
`;

export const BackgroudTotal = styled.div<Clima>`
    background-image: url('${Clima => Clima.background}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    width: 100%;
    height: 100%;
    
    position: fixed;

    display:flex;
    justify-content: center;
    align-items: center;
`;

export const ClimaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`;

export const ClimaHoje = styled.div`
    width: 100%;
    height: 100%; 
    opacity: 0.9;
    border-radius: 15px 15px 0 0;
    background: rgb(11,4,115);
`;

export const Linha = styled.hr`
    width:1px;
    border: solid;
    color: #F47920;
    background-color: white;
    border-radius: 10px;
    height: 270px;
    opacity: 10;

`;

export const ClimaProximaSemana = styled.div`
    
    background: #191970;
    opacity: 0.9;
    border-radius: 0 0 15px 15px;
    padding: 2rem 1rem;

`;

export const Titulo = styled.h1`
  text-align: center;
  color: #F47920;
  margin-top:10px;
`;

export const ImagemClima = styled.img<Imagem>`
    src: url('${Imagem => Imagem.src}');
    object-fit: contain;
    object-position: center top;
`;

export const Texto = styled.h1`
  text-align: center;
  color: Yellow;
  margin-top:10px;
`;

export const CardProximaSemana = styled.div`
    width: 100%;
    height: 150px;

    border-width: 2px;
    border-color: #0000CD;
    border-style: solid;
    background-color: #0000CD;
    border-radius: 15px;
    
`;

export const DiasSemanas = styled.div`
    position:relative;
    top: -15px;
    left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Pregressbar = styled.div<IProgress>`
    position: fixed;
    bottom: 0;
    left: 0;

    display: block;

    width: ${props => props.time ? props.time + '%' : '100%'};
    height: 2px;
    
    background: ${COLORS.GRAY_DARK};
`
export const BackGroundDepoimentos = styled.div<DepoimentoBackGround>`
    background-image: url('${DepoimentoBackGround => DepoimentoBackGround.background}');
    background-position: center center;
    background-repeat: no-repeat;

    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TitleDepoimento = styled.h1`
    color: #183A68;

    font-size: 2.3rem;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-weight: 900;    

    text-transform: uppercase;

    margin-bottom: 1rem;
`

export const TextDepoimento = styled.h1`
    color: #183A68;

    font-size: 2rem;
    font-family: 'Poppins', sans-serif;    
    font-weight: 600; 

    text-transform: uppercase;

    margin-bottom: 1rem;
`

export const SubTitleDepoimento = styled.h1`
        color: #183A68;

        font-size: 1.2rem;
        font-family: 'Poppins', sans-serif;    
        font-weight: 300; 

        text-transform: uppercase;
`
