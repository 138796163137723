import styled from "styled-components";

interface IDestaque {
    background: string;
}

interface Clima {
    background: string;
}

export const Imagens = styled.div<IDestaque>`
    background-image: url('${IDestaque => IDestaque.background}');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;

    padding-top: 15%;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;    
`;