import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';

const GlobalStyle = createGlobalStyle`   
    *{
        font-family: 'Poppins', sans-serif;
    }

    html,
    body, 
    #root {
        height: 100vh;
        background-color: ${COLORS.GRAY_LIGHT};
    }

    .carousel, .carousel-inner, .carousel-item {
        height: 100vh;
    }
`;

export default GlobalStyle; 